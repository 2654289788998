import Vue from 'vue'
import { ValidationObserver, ValidationProvider, localize, extend, Validator } from 'vee-validate';
import es from 'vee-validate/dist/locale/es.json';
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

localize('es', es);

/* Regla de validación de teléfonos fijos y móviles */
const PHONEREG = /^(\+34|0034|34)?[6789]\d{8}$/;
extend('phone', {
    validate: value => PHONEREG.test(value)
});
localize({
    es: {
      messages: {
        phone: field =>  `El campo ${field} no es válido`
      }
    }
});
/****************************************************/

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

//Control de rutas privadas
router.beforeEach((to, from, next) => {
    store.commit('setBodyClass', null)
    
    //Comprobamos si la ruta tiene definida la meta requiresAuth
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const token = store.state.auth.token

    if (!requiresAuth && token.length && to.path === '/login') {
        //Si el usuario está logueado e intenta volver al login, redirigimos a /private
        return next('/private')
    } else if (requiresAuth && !token.length) {
        //Si el usuario no está logueado impedimos que acceda a /private
        return next('/login')
    }
    return next()
})

export default router

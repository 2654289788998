<template>
    <div id="app">
        <loading 
            :active.sync="loading"
            :loader="'dots'"
            :color="'#d9534f'"
        >
        </loading>
        <router-view :key="$route.fullPath"/>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    metaInfo: {
        htmlAttrs: {
            lang: 'es'
        }
    },
    components: {
        Loading
    },
    computed: {
        ...mapState(['loading', 'bodyClass']),
    },
    watch: {
        bodyClass() {
            document.body.classList.remove(...document.body.classList)
            this.bodyClass && this.bodyClass.length && document.body.classList.add(this.bodyClass)
        }
    } 
}
</script>

<style lang="scss">
    @import 'node_modules/bootstrap/scss/bootstrap';
    @import 'node_modules/bootstrap-vue/src/index.scss';
    @import 'assets/scss/theme.scss';
</style>
import Vue from 'vue'

export async function signIn({commit}, user) {
    try {
        commit('setLoading', true, { root: true })
        const {data} = await Vue.axios({
            method: 'POST',
            url: '/private/token',
            data: user
        })

        if (data.success) {
            commit('auth/setToken', {'token': data.token}, { root: true })
        } else {
            commit('auth/authError', {'error': true, 'errorMessage': 'El usuario o la contraseña son incorrectos'}, { root: true })
        }
    } catch(e) {
        const msg = e.response.status === 401 ? 'El usuario o la contraseña son incorrectos' : e.message
        commit('auth/authError', {'error': true, 'errorMessage': msg}, { root: true })
    } finally {
        commit('setLoading', false, { root: true })
    }
}

export async function signOut({commit, state}) {
    try {
        commit('setLoading', true, { root: true })
        const {data} = await Vue.axios({
            method: 'POST',
            url: '/private/token/expired',
            data: {'token': state.token}
        })
        commit('auth/logout', null, { root: true })
    } catch(e) {
        
    } finally {
        commit('setLoading', false, { root: true })
    }
}

export async function refreshToken({commit, state}) {
    try {
        const {data} = await Vue.axios({
            method: 'POST',
            url: '/private/token/refresh',
            data: {'token': state.token},
            checkTokenExp: false
        })
        if (data.success) {
            commit('auth/setToken', {'token': data.token}, { root: true })
        } else {
            commit('auth/logout', null, { root: true })
        }
    } catch(e) {
        commit('auth/logout', null, { root: true })
    }
}
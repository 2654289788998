import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from '../router'
import store from '../store'
import parseJwt from '../mixins/parseJwt'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.request.use(
    async (config) => {
        try {
            let token = !config.url.startsWith('/public/') ? store.state.auth.token : ''
            const checkTokenExp = config.hasOwnProperty('checkTokenExp') ? config.checkTokenExp : true 
            if (token && checkTokenExp) {
                const parsedToken = parseJwt.methods.parseJwt(token)
                const timeNow = Math.round(new Date() / 1000)
                if (parseInt(timeNow) >= parseInt(parsedToken.exp)) {
                    await store.dispatch('auth/refreshToken')
                    token = store.state.auth.token
                    if (!token.length) {
                        // Clear token and redirect
                        store.commit('auth/logout', null, { root: true })
                        router.push('/login').catch(err => {})
                    }
                }
            }

            if (token) {
                config.headers['Authorization'] = `Bearer ${ token }`
            }
        } catch (e) {
            
        } finally {
            return config
        }
    }, 
    (error) => {
        return Promise.reject(error)
    }
);

axios.interceptors.response.use(
    response => response,
    error => {
        try {
            let response = error.response && error.response
            let config = response && response.config
            if (config && config.url.includes('/private/token/refresh') && response.status === 401) {
                // Clear token and redirect
                store.commit('auth/logout', null, { root: true })
                router.push('/login').catch(err => {})
            }
        } catch(e) {
            console.log(e)
        } finally {
            return Promise.reject(error)
        }
    },
);

//axios.defaults.headers.common['Authorization'] = `Basic ${btoa(unescape(encodeURIComponent('user:pass')))}`
Vue.use(VueAxios, axios)
import parseJwt from '../../../mixins/parseJwt'

export default (store) => {
    const ability = store.getters.ability

    const token = store.state.auth.token
    const parsedToken = token ? parseJwt.methods.parseJwt(token) : ''
    parsedToken && ability.update(parsedToken.data.rules)

    return store.subscribe((mutation) => {
        switch (mutation.type) {
            case 'auth/setToken':
                const token = mutation.payload.token
                const parsedToken =token ? parseJwt.methods.parseJwt(token) : ''
                ability.update(parsedToken.data.rules)
                break
            case 'auth/logout':
                ability.update([])
                break
        }
    })
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueYandexMetrika from 'vue-yandex-metrika' 
require('./plugins')

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'
//Vue.config.performance = process.env.NODE_ENV !== 'production'

Vue.use(VueYandexMetrika, {
    id: 64516225,
    router: router,
    env: process.env.NODE_ENV,
    options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

const modalData = () => {
    return {
        selectedType: null,
        selectedOffice: null,
        selectedProvince: null,
        selectedPlace: null,
        selectedDistrict: '',
        officeSearch: '',
        dateFrom: null,
        dateTo: null,
        enableDownload: false
    }
}

export default {
    namespaced: true,
    state: {
        modalData: modalData()
    },
    mutations: {
        setModalData(state, data) {
            state.modalData[data.name] = data.value
        },
        resetModalData(state) {
            state.modalData = modalData()
        }
    }
}

require('./bootstrap-vue')
require('./vue-axios')
require('./vee-validate')
require('./vue-good-table')
require('./fontawesome')
require('./formatters')
require('./masked-input')
require('./vue-meta')
require('./casl')
require('./vue-gtag')
require('./vue-notification')
require('./vue2-google-maps')
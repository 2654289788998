export function setToken(state, payload) {
    state.token = payload.token
    state.error = false
}

export function logout(state) {
    state.token = ''
    state.error = false
}

export function authError(state, payload) {
    state.token = ''
    state.error = payload.error
    state.errorMessage = payload.errorMessage
}
const modalData = () => {
    return {
        selectedAssignmentType: null,
        selectedOffice: null,
        selectedPostalCode: null,
        selectedMetersSalePrice: null,
        selectedMetersRentalPrice: null
    }
}

export default {
    namespaced: true,
    state: {
        modalData: modalData()
    },
    mutations: {
        setModalData(state, data) {
            state.modalData[data.name] = data.value
        },
        resetModalData(state) {
            state.modalData = modalData()
        }
    }
}

import store from '@/store'
import parseJwt from '@/mixins/parseJwt'

export default [
    {
        path: '/',
        component: () => import( /* webpackChunkName: "main" */ '@/views/public/Main.vue')
    },
    {
        path: '/valoracion-inmueble/:slug',
        name: 'valuation',
        component: () => import( /* webpackChunkName: "main-property" */ '@/views/public/Valuation.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import( /* webpackChunkName: "login" */ '@/views/private/Login.vue')
    },
    {
        path: '/private',
        name: 'private',
        component: () => import( /* webpackChunkName: "desktop" */ '@/views/private/Desktop.vue'),
        meta: { requiresAuth: true },
        beforeEnter: async (to, from, next) => {
            const parsedToken = parseJwt.methods.parseJwt(store.state.auth.token)
            if (parsedToken) {
                return next()
            } else {
                // Clear token and redirect
                store.commit('auth/logout', null, { root: true })
                return next('/login')
            }
        },
        children: [
            {
                path: 'properties',
                name: 'properties',
                component: () => import( /* webpackChunkName: "properties" */ '@/views/private/Property/Properties.vue'),
                beforeEnter: (to, from, next) => {
                    if (from.name !== 'property') {
                        sessionStorage.removeItem('gridStatus')
                    }
                    return next()
                }
            },
            {
                path: 'properties/:slug',
                name: 'property',
                component: () => import( /* webpackChunkName: "property" */ '@/views/private/Property/Property.vue')
            },
            {
                path: 'streets',
                name: 'streets',
                component: () => import( /* webpackChunkName: "streets" */ '@/views/private/Street/Streets.vue')
            },
            {
                path: 'streets/assigned',
                name: 'assigned_streets',
                component: () => import( /* webpackChunkName: "assigned_streets" */ '@/views/private/Street/Assigned.vue')
            },
            {
                path: 'streets/pending',
                name: 'pending_streets',
                component: () => import( /* webpackChunkName: "pending_streets" */ '@/views/private/Street/Pending.vue')
            },
            {
                path: 'numbers',
                name: 'numbers',
                component: () => import( /* webpackChunkName: "numbers" */ '@/views/private/Numbers.vue')
            },
            {
                path: 'offices',
                name: 'offices',
                component: () => import( /* webpackChunkName: "offices" */ '@/views/private/Office/Offices.vue')
            },
            {
                path: 'offices/:id_office',
                name: 'office',
                component: () => import( /* webpackChunkName: "office" */ '@/views/private/Office/Office.vue')
            },
            {
                path: 'provinces',
                name: 'provinces',
                component: () => import( /* webpackChunkName: "province" */ '@/views/private/Province/Provinces.vue')
            },
            {
                path: 'places',
                name: 'place',
                component: () => import( /* webpackChunkName: "place" */ '@/views/private/Place/Place.vue')
            },
            {
                path: 'districts',
                name: 'district',
                component: () => import( /* webpackChunkName: "district" */ '@/views/private/District/District.vue')
            },
            {
                path: 'quarters',
                name: 'quarter',
                component: () => import( /* webpackChunkName: "quarter" */ '@/views/private/Quarter/Quarter.vue')
            }
        ]
    },
    { 
        path: '/gracias', 
        component: () => import( /* webpackChunkName: "thanks" */ '@/views/public/Thanks.vue')
    },
    { 
        path: '/404', 
        component: () => import( /* webpackChunkName: "404" */ '@/views/404.vue')
    },
    { 
        path: '/politica-de-cookies',
        component: () => import( /* webpackChunkName: "Cookies" */ '@/views/public/Cookies.vue')
    },
    {
        path: '*',
        name:'404', 
        component: () => import( /* webpackChunkName: "404" */ '@/views/404.vue')
    }
]